<template lang="pug">
.menu-wrapper
  .option(
    :class="{ active: tabSelected == 'AccessComponent' }",
    @click="$emit('setComponent', 'AccessComponent')"
  ) {{ $t('dashboard.button_conf') }}
  .option(
    :class="{ active: tabSelected == 'PaymentsComponent' }",
    @click="$emit('setComponent', 'PaymentsComponent')"
  ) {{ $t('dashboard.button_payments') }}
</template>

<script>
export default {
  name: "menu-pages",
  props: {
    tabSelected: {
      type: String,
      default: "AccessComponent",
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-wrapper {
  @apply grid grid-cols-1 gap-4 xs:grid-cols-2;
  .option {
    @apply text-center py-2 rounded-md cursor-pointer border border-gray-100;

    &.active {
      @apply border border-blue-100 bg-blue-50 font-medium;
    }

    &:hover {
      @apply bg-blue-50 border-blue-100;
    }
  }
}
</style>
