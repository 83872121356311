<template lang="pug">
BaseCard
  BaseCardTitle
    .flex.items-center
      BaseCustomIcon.icon(nameIcon="mdiLockOutline")
      .text {{ $t('dashboard.form_password_title') }}
  .form.pt-4
    BaseInputText(:label="$t('dashboard.label_password_actual')", v-model="old_psw" :error="old_psw_error")
    BaseInputText(:label="$t('dashboard.label_password_new')", v-model="new_psw"  :error="repeat_psw_error")
    BaseInputText(:label="$t('dashboard.label_password_repeat')", v-model="repeat_psw" :error="repeat_psw_error")
    .error(v-if="default_error") {{ default_error }}
  template(#card-bottom) 
    .flex.justify-end
      .button(@click="updatePsw") {{ $t('dashboard.button_save') }}
</template>

<script setup>
import { ref, watch } from 'vue';
import { UsersRepository } from '@/repository/users.repository';
import { useStore } from 'vuex';
import { i18n } from '@/languages';
const { t } = i18n.global;
const store = useStore();
const old_psw = ref('');
const new_psw = ref('');
const repeat_psw = ref('');

const old_psw_error = ref('');
const repeat_psw_error = ref('');
const default_error = ref('');

const isEmptyOldPsw = () => {
  const isEmpty = old_psw.value == '';
  if (isEmpty) old_psw_error.value = t('dashboard.error_psw_actual');
  return isEmpty;
}

const isIdemNewPsw = () => {
  const isEmptyNewPsw = repeat_psw.value == '';
  if (isEmptyNewPsw) repeat_psw_error.value = t('dashboard.error_empty_new_psw');
  if (repeat_psw.value.length < 5) repeat_psw_error.value = t('dashboard.error_minim_characters');
  const isIdem = new_psw.value == repeat_psw.value;
  if (!isIdem) repeat_psw_error.value = t('dashboard.error_not_match');
  return repeat_psw_error.value == '';
}

const updatePsw = async () => {
  if (!isEmptyOldPsw() && isIdemNewPsw()) {
    UsersRepository.setPassword({
      old_psw: old_psw.value,
      new_psw: new_psw.value,
      repeat_psw: repeat_psw.value
    }).then(() => {
      store.dispatch('UserStore/logout');
    }).catch(({ response }) => {
      switch (response.data.message) {
        case "old_psw_not_correct":
          old_psw_error.value = t('dashboard.error_psw_not_correct');
          break;
        default:
          default_error.value = t('dashboard.error_psw_default');
      }
    })
  }
}

watch(old_psw, () => {
  old_psw_error.value = '';
})
watch(new_psw, () => {
  repeat_psw_error.value = '';
})
watch(repeat_psw, () => {
  repeat_psw_error.value = '';
})

</script>

<style lang="scss" scoped>
.form {
  @apply flex flex-col gap-4;

  .error {
    @apply text-sm text-center text-red-400;
  }
}

.button {
  @apply bg-indigo-600 text-white px-4 py-2 rounded-md cursor-pointer;
}

.text {
  @apply text-base xs:text-xl;
}

.icon {
  @apply flex-shrink-0 mr-2;
}
</style>
