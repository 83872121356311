<template lang="pug">
FormEmailComponent
FormPasswordComponent.mt-12
</template>

<script>
import FormEmailComponent from "@/components/www/dashboard/my-account/form-email.vue";
import FormPasswordComponent from "@/components/www/dashboard/my-account/form-password.vue";
export default {
  name: "access-component",
  components: {
    FormEmailComponent,
    FormPasswordComponent,
  },
};
</script>

<style lang="scss" scoped></style>
