export default () => {
  const twoDigits = digit => {
    return digit < 10 ? `0${digit}` : digit;
  };

  const parseDate = dateToParse => {
    const date = new Date(dateToParse);
    return `${date.getDate()}/${twoDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
  };

  const parseHour = dateToParse => {
    const date = new Date(dateToParse);
    return `${twoDigits(date.getHours())}:${twoDigits(date.getMinutes())}`;
  };

  return {
    parseDate,
    parseHour
  };
};
