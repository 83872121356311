<template lang="pug">
BaseCard
  BaseCardTitle 
    .flex.items-center
      BaseCustomIcon.icon(nameIcon="mdiEmailOutline")
      .text {{ $t('dashboard.form_email_title') }}
  .form.pt-4
    BaseInputText(
      :label="$t('dashboard.label_email')",
      :disabled="true",
      v-model="user_email"
    )
</template>

<script>
export default {
  name: "form-email",
  computed: {
    user_email() {
      return this.$store.getters["UserStore/getUserEmail"];
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  @apply text-base xs:text-xl;
}

.icon {
  @apply flex-shrink-0 mr-2;
}
</style>
